<template>
  <div>
    <v-form ref="form" lazy-validation>
      <div class="title text-h6 px-3">
        Edit Document
      </div>

      <v-container>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-text-field
              v-model="data.name"
              label="Name"
              placeholder="Enter Document Name"
              :rules="[rules.required]"
              v-bind="basic"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-file-input
              v-model="data.document"
              label="Document"
              truncate-length="15"
              v-bind="basic"
            ></v-file-input>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-menu
              v-model="menu1"
              :close-on-content-click="false"
              max-width="290"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="data.expiry_date"
                  clearable
                  label="Expiry Date"
                  readonly
                  v-bind="{ ...basic, ...attrs }"
                  v-on="on"
                  @click:clear="data.expiry_date = null"
                  :rules="[rules.required]"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="data.expiry_date"
                @change="menu1 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              color="success"
              class="mr-4"
              v-on:click="submit"
              elevation="2"
              large
              block
            >
              Submit
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>
<script>
import util from "@/util/util";
export default {
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      menu1: false,
      data: {
        name: "",
        expiry_date: "",
        document: null,
      },
      rules: util.validation,
      basic: util.input.basic,
      loading: false,
    };
  },
  mounted() {
    this.fetchDocument();
  },
  methods: {
    submit() {
      const headers = { "Content-Type": "multipart/form-data" };
      const formData = new FormData();
      formData.append("_method", "put");
      formData.append("name", this.data.name);
      formData.append("document", this.data.document);
      formData.append("expiry_date", this.data.expiry_date);
      if (this.$refs.form.validate()) {
        this.loading = true;
        util
          .http({
            url: "core/document/" + this.$props.id,
            method: "post",
            data: formData,
            headers,
          })
          .then((res) => {
            this.loading = false;
            if (res.data.status == 1) {
              util.notify(1, "Document Stored");
              this.$emit("closeDialog");
              this.data = {};
            } else {
              util.notify(0, res.data.errors[0]);
            }
          });
      }
    },

    fetchDocument() {
      this.loading = true;
      util
        .http({
          url: "core/document/" + this.$props.id,
          method: "get",
        })
        .then((res) => {
          this.loading = false;
          if (res.data.status == 1) {
            this.data = {
              name: res.data.data.name,
              expiry_date: res.data.data.expiry_date,
            };
          } else {
            util.notify(0, res.data.errors[0]);
          }
        });
    },
  },
};
</script>
