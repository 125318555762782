<template>
  <div>
    <div class="title text-h6 px-3">
      View Document
    </div>
    <v-container>
      <v-row>
        <div
          class="d-flex justify-space-between mr-4 ml-4 mt-2"
          style="width:100%"
        >
          <span>Document : {{ data.name }}</span>
          <span>Expiry Date : {{ data.expiry_date }}</span>
        </div>
        <v-col cols="12" sm="12" md="12">
          <embed :src="data.url" class="document-image" height="1200" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import util from "@/util/util";
export default {
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      loading: false,
      data: null,
    };
  },
  mounted() {
    this.fetchDocument();
  },
  methods: {
    fetchDocument() {
      this.loading = true;
      util
        .http({
          url: "core/document/" + this.$props.id,
          method: "get",
        })
        .then((res) => {
          this.loading = false;
          if (res.data.status == 1) {
            this.data = {
              name: res.data.data.name,
              expiry_date: res.data.data.expiry_date_formatted,
              url: res.data.data.url,
            };
          } else {
            util.notify(0, res.data.errors[0]);
          }
        });
    },
  },
};
</script>

<style>
.document-image {
  width: 100%;
}
</style>
