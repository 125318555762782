<template>
  <div>
    <div>
      <span>
        <v-icon @click="viewDOcument" small title="View">
          mdi-eye
        </v-icon>
      </span>
      |
      <span>
        <v-icon @click="editProduct" small title="Edit">
          mdi-pencil
        </v-icon>
      </span>
      |
      <span>
        <v-icon @click="deleteObject" small title="Delete">
          mdi-delete
        </v-icon>
      </span>
      <slot></slot>
      <v-dialog v-model="showModal" max-width="500px">
        <deleteDialog
          resource="core/document"
          :id="$props.delete.params.id"
          @cancel="cancel"
          @reload-resource="deleteSuccess"
        />
      </v-dialog>
    </div>
  </div>
</template>
<script>
import util from "@/util/util";
import deleteDialog from "@/components/shared/delete-modal";
export default {
  components: { deleteDialog },
  props: {
    delete: {
      type: Object,
    },
    item: {
      type: Object,
    },
  },
  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    deleteObject() {
      this.showModal = true;
    },
    cancel() {
      this.showModal = false;
    },
    deleteSuccess() {
      console.log("asd");
      this.showModal = false;
      util.event.$emit("reload-resource", true);
    },
    editProduct() {
      util.event.$emit("edit-document", this.$props.item);
    },
    viewDOcument() {
      util.event.$emit("view-document", this.$props.item);
    },
  },
};
</script>
<style scoped>
.fa-trash {
  color: #cc0000;
  cursor: pointer;
}

.fa-edit {
  color: #ffbf00;
}
.confirm-box {
  display: flex;
  justify-content: space-evenly;
}
.nav-link-button {
  display: inline !important;
  padding: 2px !important;
}
a {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87) !important ;
}
</style>
