<template>
  <div>
    <component-layout title="Documents">
      <template #menu>
        <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn dark icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              v-ripple="{ center: true }"
              class="route-button"
              @click="showAddDocument()"
            >
              ADD
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <vue-table
        itemName="document"
        :key="key"
        :url="table.url"
        :except="table.except"
        :sn="false"
        :headingTransformer="table.headingTransformer"
        :html="table.html"
        :valueTransformer="table.valueTransformer"
        :additionalColumns="table.additionalColumns"
        :additionalColumnsTransformer="table.additionalColumnsTransformer"
        :columnSortOrder="table.columnSortOrder"
      ></vue-table>
    </component-layout>
    <v-dialog
      v-model="showAddDocumentDialog"
      max-width="600px"
      :key="key + 'a'"
    >
      <addDocument @closeDialog="closeDialog" />
    </v-dialog>
    <v-dialog
      v-model="showEditDocumentDialog"
      max-width="600px"
      :key="key + 'b'"
    >
      <editDocument @closeDialog="closeDialog" :id="documentId" />
    </v-dialog>
    <v-dialog v-model="showViewDocumentDialog" max-width="60%" :key="key + 'c'">
      <viewDocument @closeDialog="closeDialog" :id="documentId" />
    </v-dialog>
  </div>
</template>
<script>
import util from "@/util/util";
import VueTable from "../shared/vue-table.vue";
import ComponentLayout from "../component-layout.vue";
import addDocument from "./create.vue";
import editDocument from "./edit.vue";
import viewDocument from "./view.vue";
import action from "./action";
export default {
  components: {
    VueTable,
    ComponentLayout,
    addDocument,
    editDocument,
    viewDocument,
  },
  data() {
    return {
      key: 0,
      documentId: 0,
      searchTerm: "",
      showAddDocumentDialog: false,
      showEditDocumentDialog: false,
      showViewDocumentDialog: false,
      table: {
        url: "core/document",
        columnSortOrder: {
          name: 1,
          Document: 2,
          expiry_date: 3,
          Actions: 4,
        },
        except: [
          "id",
          "created_at",
          "updated_at",
          "deleted_at",
          "url",
          "expiry_date_formatted",
          "status",
        ],
        html: ["Actions", "Document", "expiry_date"],
        additionalColumns: ["Actions", "Document"],
        headingTransformer: function(val) {
          return util.camelCase(val);
        },
        valueTransformer: function() {
          return {
            expiry_date: (row) => {
              return [
                {
                  item: row.expiry_date_formatted,
                  handler: () => null,
                },
              ];
            },
            // status: (row) => {
            //   return [
            //     {
            //       item:
            //         '<span style="color:white;padding:0.4rem" class="badge ' +
            //         (row.status == "Inactive"
            //           ? "new badge red"
            //           : "new badge green") +
            //         '">' +
            //         (row.status == "Inactive" ? "Not Active" : "Active") +
            //         "</span>",
            //       handler: () => null,
            //     },
            //   ];
            // },
          };
        },
        additionalColumnsTransformer: function() {
          return {
            Document: (row) => {
              return [
                {
                  item: row.url
                    ? ` <embed src="${row.url}" width="300" />`
                    : `<img src="https://i.ibb.co/9qNQyd2/download.png" width="150">`,
                  handler: () => null,
                },
              ];
            },
            Actions: (row) => {
              return [
                {
                  comp: action,
                  prop: {
                    item: row,
                    delete: {
                      params: { name: "document", id: row.id },
                    },
                  },
                },
              ];
            },
          };
        },
      },
    };
  },
  mounted() {
    util.event.$on("reload-resource", () => {
      this.key++;
    });
    util.event.$on("edit-document", (document) => {
      this.showEditModal(document.id);
    });
    util.event.$on("view-document", (document) => {
      this.showViewModal(document.id);
    });
  },
  methods: {
    showAddDocument() {
      this.showAddDocumentDialog = true;
    },
    showEditModal(id) {
      this.documentId = id;
      this.key++;
      this.showEditDocumentDialog = true;
    },
    closeDialog() {
      this.showAddDocumentDialog = false;
      this.showEditDocumentDialog = false;
      this.showViewDocumentDialog = false;
      this.key++;
    },
    showViewModal(id) {
      this.documentId = id;
      this.key++;
      this.showViewDocumentDialog = true;
    },
  },
};
</script>
<style>
.route-button {
  cursor: pointer !important;
}
</style>
